import React from 'react';

export default function() {
  return (
    <footer className="position-relative" id="footer-main">
      <div className="footer pt-lg-6 footer-dark bg-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <h3 className="text-secondary mb-2">Privacy-First Contact Tracing for Communities</h3>
                  <p className="lead mb-0 text-white opacity-8">
                    Built for real humans in need by real humans who care.
                  </p>
                </div>
                <div className="col-lg-5 text-lg-right mt-4 mt-lg-0">
                  <a className="btn btn-secondary my-2 ml-0 ml-sm-3" href="/volunteer">
                    Join Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider divider-fade divider-dark my-5" />
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <a href="/">
                <img alt="Zerobase logo" src="/assets/img/ZerobaseLogo_Dark.png" style={{ width: '200px' }} />
              </a>
              <p className="mt-4 text-sm opacity-8 pr-lg-4">
                The Zerobase Foundation is a nonprofit organization whose mission is to build free, open source public
                health technology for the good of communities around the world. Our free, privacy-first contact tracing
                empowers both individuals and communities to stop the spread of COVID-19.
              </p>
              <ul className="nav mt-4">
                <li className="nav-item">
                  <a className="nav-link" href="https://github.com/zerobase-io" target="_blank">
                    <i className="fe fe-github"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.instagram.com/zerobase.app" target="_blank">
                    <i className="fe fe-instagram"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.twitter.com/zerobaseio" target="_blank">
                    <i className="fe fe-twitter"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://necsi-edu.slack.com/join/shared_invite/zt-cu5215sg-63h4A7uCy~ehDsrfAIJ~_Q"
                    target="_blank"
                  >
                    <i className="fe fe-slack"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 className="heading mb-3">Register</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="/business/register">As a Business</a>
                </li>
                <li>
                  <a href="/healthcare/register">As a Healthcare Provider</a>
                </li>
                <li>
                  <a href="/healthcare/register">As a Testing Facility</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 className="heading mb-3">Information For</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="/individual">Individuals</a>
                </li>
                <li>
                  <a href="/businesses">Businesses & Locations</a>
                </li>
                <li>
                  <a href="/testing">Testing Facilities</a>
                </li>
                <li>
                  <a href="/community">Communities</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 className="heading mb-3">Zerobase Foundation</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/team">Our Team</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/feedback">Feedback</a>
                </li>
              </ul>
            </div>
          </div>
          <hr className="divider divider-fade divider-dark my-4" />
          <div className="row align-items-center justify-content-md-between pb-4">
            <div className="col-md-6">
              <div className="copyright text-sm font-weight-bold text-center text-md-left">
                &copy; 2020{' '}
                <a className="font-weight-bold" href="https://github.com/zerobase-io/" target="_blank">
                  Zerobase
                </a>
                . All rights reserved. v2.0.1
              </div>
            </div>
            <div className="col-md-6">
              <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                <li className="nav-item">
                  <a className="nav-link" href="/terms" target="_blank">
                    Terms
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/privacy-policy">
                    Privacy & Cookies Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
